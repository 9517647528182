jQuery(window).on("load", function () {

    if ($("header").offset().top > 100) {
        setTimeout(function () {
            $('header').addClass('menu-up');
        }, 100);
    }

    /*$(function () {
        var $el = $('.first-screen-bg');
        $(window).on('scroll', function () {
            var scroll = $(this).scrollTop();
            $el.css({
                'transform': 'translate3d(0,0,0)'
            });
        });
    });

    $(function () {
        var $el = $('.info-block-bg');
        $(window).on('scroll', function () {
            var scroll = $(this).scrollTop() - $('.info-block').offset().top;
            $el.css({
                'background-position': '50% ' + (-.2 * scroll) + 'px'
            });
        });
    });

    $(function () {
        var $el = $('.years-block-bg');
        $(window).on('scroll', function () {
            var scroll = $(this).scrollTop() - $('.years-block').offset().top;
            $el.css({
                'background-position': '50% ' + (-.2 * scroll) + 'px'
            });
        });
    });*/

    if ($('.first-screen-bg').height() + 250 < $(this).scrollTop()) {
        $('.info-block-bg').css('z-index', '-1');
    } else {
        $('.info-block-bg').css('z-index', '-2');
    }

    if ($('.years-block').offset().top - $('.years-block-bg').height() - 200 < $(this).scrollTop()) {
        $('.years-block-bg').css('z-index', '-1');
    } else {
        $('.years-block-bg').css('z-index', '-2');
    }
});

$(document).ready(function () {

    $("#menu").mmenu({
        "extensions": [
                "position-right",
                "pagedim-black",
                "border-offset"
             ],
        classNames: {
            fixedElements: {
                fixed: "fix-menu"
            },
            vertical: "expand"
        }
    });

    $("#menu").mhead();

    var api = $('#menu').data('mmenu');
    api.bind("close:finish", function () {
        $('.ham').removeClass('active');
        $('.icon').removeClass('open');
    });

    $("nav .mm-listitem__text").click(
        function (evnt) {
            api.close();
        }
    )

    $(window).scroll(function (event) {

        if ($('.first-screen-bg').height() + 250 < $(this).scrollTop()) {
            $('.info-block-bg').css('z-index', '-1');
        } else {
            $('.info-block-bg').css('z-index', '-2');
        }

        if ($('.years-block').offset().top - $('.years-block-bg').height() - 200 < $(this).scrollTop()) {
            $('.years-block-bg').css('z-index', '-1');
        } else {
            $('.years-block-bg').css('z-index', '-2');
        }
    });


    if ($(window).width() < 1200) {
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();
            /*if (st > lastScrollTop) {
                $('.fix-menu').addClass('mh-scrolledout');
            } else {
                $('.fix-menu').removeClass('mh-scrolledout');
            }*/
            if (st > $(window).height()) {
                $('.fix-menu').addClass('mh-scrolledout');
            } else {
                $('.fix-menu').removeClass('mh-scrolledout');
            }
            if (st > lastScrollTop) {
                $('.fix-menu').addClass('menu-down');
            } else if (st == 0) {
                $('header').removeClass('menu-down');
            } else {
                $('.fix-menu').removeClass('mh-scrolledout');
            }

            lastScrollTop = st;
        });
    } else {
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();
            if (st > $(window).height()) {
                $('header').removeClass('menu-down');
                $('header').addClass('menu-up');
            } else {
                $('header').addClass('menu-down');
                $('header').removeClass('menu-up');
            }
            if (st > lastScrollTop) {
                $('header').addClass('menu-down');
            } else if (st == 0) {
                $('header').removeClass('menu-down');
            } else {
                $('header').removeClass('menu-up');
                $('header').addClass('menu-down');
            }

            lastScrollTop = st;

        });

        $(".vert").paroller({
            factor: 0.23,
            type: 'foreground',
            direction: 'vertical'
        });

        $(".horiz").paroller({
            factor: 0.22,
            type: 'foreground',
            direction: 'horizontal'
        });

    }

    $('.jarallax').jarallax({
        speed: 0.1
    });

    new WOW().init();

    jQuery('textarea').autoResize();

    var greenColor = String('#28a745');
    var redColor = String('#E11B16');

    jQuery(function () {
        var form = $('.contact-block form');
        var formInfo = $('.contact-block form .info');
        $(form).validate({
            submitHandler: function (form) {
                $(form).ajaxSubmit({
                    clearForm: true,
                    success: function () {
                        $(form).find("input, textarea").val("");
                        $(formInfo).css('color', greenColor);
                        $(formInfo).text('Thanks! Your request has been sent. Our staff will contact you.');
                        $(formInfo).slideDown();
                        setTimeout(function () {
                            $(formInfo).slideUp();
                        }, 7000)
                    },
                    error: function () {
                        $(form).find("input, textarea").val("");
                        $(formInfo).css('color', redColor);
                        $(formInfo).text('Your request has not been sent. Try again in a few minutes.');
                        $(formInfo).slideDown();
                        setTimeout(function () {
                            $(formInfo).slideUp();
                        }, 7000)
                    }
                });
            }
        });
    });

});
